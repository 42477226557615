import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import africamap from "../assets/africamap.png";
import MAAWhite from "../assets/MAA_black.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import MenuBar from "./menubar.jsx";
import InstagramIcon from "@mui/icons-material/Instagram";
import { motion } from "framer-motion";

export default function Header() {
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);
  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
    },
  });

  const [isScrolled, setIsScrolled] = useState(false);


useEffect(() => {



  const target = document.getElementById("trigger-section");
  // const rect = target.getBoundingClientRect();
  // console.log("Section Rect:", rect);
  // console.log("Section Top:", rect.top);
  // console.log("Section Bottom:", rect.bottom);
  // console.log("Section Height:", rect.height);
  if (!target) {
    console.warn("⚠️ Trigger section not found!");
    return;
  } else {
    console.log("✅ Trigger section found!");
  }

  // Create the IntersectionObserver instance

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        
        setIsScrolled(true);
      } else {
        
        setIsScrolled(false);
        
      }
    },
    {
      root: null, // Use the viewport as the root
      rootMargin: "0px 0px -5% 0px", // Trigger when the section is 50% in view
      threshold: 0, 

    }
  );

  // Get the target section element
  const section = document.getElementById("trigger-section");

  // Observe the target section
  if (section) {
    observer.observe(section);
  }
  //const target = document.getElementById("trigger-section")
  console.log("Viewport Height:", window.innerHeight);
    console.log("Section Top:", target.getBoundingClientRect().top);
    console.log("Section Bottom:", target.getBoundingClientRect().bottom);
  
  // Clean up the observer when the component is unmounted
  return () => {
    if (section) {
      observer.unobserve(section);
    }
  };
}, []);


const [mounted, setMounted] = useState(false);  // Track if the component has mounted

// Run the effect once when the component mounts
useEffect(() => {
  setMounted(true);  // Set the mounted state to true after initial render

  const handleScroll = () => {
    if (window.scrollY > 50 && !isScrolled) {
      setIsScrolled(true); // Change logo when scrolling starts
    } else if (window.scrollY <= 50 && isScrolled) {
      setIsScrolled(false); // Revert logo when scroll goes back to top
    }
  };

  // Add scroll event listener
  window.addEventListener('scroll', handleScroll);

  // Cleanup event listener on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [isScrolled]); 

if (!mounted) {
  return null; 
}

  
  return (
    <Box sx={{ flexGrow: 1, 
     position: "sticky",
     top: 0, 
  overflow: "visible",
      zIndex: 1201
      }}>
      <AppBar
     
        position="fixed"
        sx={{
          //zIndex: 1201,
          zIndex: 9999,
          top: 0,
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          boxShadow: "none",
          borderBottom: "1px solid black",
          padding: 0,
        }}
      >
        <Toolbar
          sx={{
            borderBottom: "1px ",
            boxShadow: "none",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            height: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          <MenuBar />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
            viewport={{ amount: 0.2 }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <img
                src={isScrolled ? africamap : MAAWhite}
                alt="MAA logo"
                width="130"
                style={{
                  height: isScrolled ? "60px" : "160px",
                  opacity: isScrolled ? 0.9 : 1,
                  objectFit: "contain",
                  transition:
                    "height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                }}
              />
            </motion.div>
          </Box>

          {/* Social Icons */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={getAnimation(1)}
            viewport={{ amount: 0.2 }}
          >
            <Box sx={{ ml: "auto", display: "flex" }}>
              <IconButton
                sx={{
                  transition: "transform 0.2s ease, color 0.2s ease",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#A0A0A0",
                    transform: "scale(1.0)",
                  },
                }}
                color="black"
                component="a"
                href="https://www.facebook.com/share/16C3pyTMYm/?mibextid=wwXIfr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                sx={{
                  transition: "transform 0.2s ease, color 0.2s ease",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#D8D8D8",
                    transform: "scale(1.0)",
                  },
                }}
                color="black"
                component="a"
                href="https://www.instagram.com/megaleioadvertising?igsh=NmRxcm84dnNwbDNn&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          </motion.div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
