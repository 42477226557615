import React from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardMedia,
} from "@mui/material";
import { Divider } from "@mui/material";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const packages = [
  {
    image: "assets/pac1.jpeg",
  },
  {
    image: "assets/pac2.jpeg",
  },
  {
    image: "assets/pac3.jpeg",
  },
];

export default function ServicePage() {

  const [scrollDirection, setScrollDirection] = useState("down");
  
    // Detect scroll direction
    useEffect(() => {
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        setScrollDirection(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY;
      };
  
      window.addEventListener("scroll", updateScrollDirection);
      return () => window.removeEventListener("scroll", updateScrollDirection);
    }, []);
  
    // Animation variants based on scroll direction
    const getAnimation = (index) => ({
      hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
      },
    });
  
   
  
  return (
    <div
  id="trigger-section"
  style={{
    // Full content height and a neutral background for visualization
    minHeight: "100vh",
    //backgroundColor: "lightgray",
  }}
>
    <Box
      sx={{
       
      }}
    >
      {/* Image on Top */}

      <Box
        sx={{
          minHeight: { xs: "120vh", sm: "130vh", md: "130vh", lg: "130vh" },
          //minHeight: { xs: "105vh", sm: "110vh", md: "110vh", lg: "110vh" },
          overflowX: "hidden",
          overflowY: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",
          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          animation: "moveBackground 30s linear infinite",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: 2,
                textTransform: "uppercase",
                fontSize: { xs: "1rem", sm: "1.3rem" },
              }}
            >
              Megaleio Advertising Agency
            </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, fontSize: { xs: "3rem", sm: "3.8rem" } }}
            >
              OUR SERVICES
            </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",

            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },
            backgroundImage: `url('/assets/DigitalScreens.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Digital Display
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: 4,

            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          >
             <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,

                textTransform: "uppercase",
              }}
            >
              Our digital display services help businesses create dynamic,
              high-impact visuals for screens of all sizes. We specialize in:
            </Typography>
            </motion.div>

            {/* Divider Centered */}
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "40%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              "LED & Digital Signage Content – Designing engaging visuals for indoor and outdoor digital displays.",
              "Interactive Displays – Developing touchscreen and interactive content for kiosks and events.",
              "Advertising & Promotions – Creating compelling digital ads for billboards, retail screens, and transit displays.",
              "Motion Graphics & Animations – Enhancing digital signage with smooth, eye-catching animations.",
              "Menu Boards & Retail Displays – Designing digital menus and in-store promotions for restaurants and retail spaces.",
              "We bring your brand to life with visually striking digital content that captivates audiences and drives engagement.",
            ].map((text, index) => (
               <motion.div initial="hidden" whileInView="visible" 
               //variants={getAnimation(0)} 
               variants={getAnimation(index + 5)}
               viewport={{ amount: 0.2 }}>
                <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
            ))}
          </Container>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundImage: `url('assets/Content_Creation.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },
          width: "100%",
          marginBottom: 3,
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h2"
              sx={{
                spacing: 3,
                marginTop: {xs:"35%",sm:"28%",},
                color: "white",
                fontWeight: 400,
                fontSize: { xs: "3rem", sm: "3.8rem" },
              }}
            >
              CONTENT CREATION
            </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      {/* Description Below */}
      <Box sx={{ padding: 4, margin: 0 }}>
        <Grid
          container
          spacing={1}
          sx={{
            minHeight: "40vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Description */}
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{ paddingX: 4, marginTop: 5, textAlign: "center" }}
          >
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,

                textTransform: "uppercase",
              }}
            >
              Our content creation services help businesses engage their
              audience with high-quality, compelling content tailored to their
              brand. We specialize in:
            </Typography>
            </motion.div>
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "45%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              "Blog & Article Writing - Crafting informative, SEO-optimized content to drive traffic and engagement.",
              "Photography & Visual Content – Capturing stunning images for digital and print use.",
              "Copywriting & Brand Messaging – Developing persuasive copy for websites, ads, and marketing materials.",
              "Video Production & Editing – Producing professional videos, from promotional clips to storytelling content.",
              "Social Media Content – Creating eye-catching graphics, videos, and captions for various platforms.",
              "We create content that connects, informs, and inspires, helping your brand stand out in a crowded digital landscape.",
            ].map((text, index) => (
              <motion.div initial="hidden"
               whileInView="visible" 
               variants={getAnimation(index + 5)}
               viewport={{ amount: 0.2 }}>
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  fontSize: 15,
                  letterSpacing: 1,
                  marginBottom: 3,
                  color: "black",
                }}
              >
                {text}
              </Typography>
             </motion.div>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },
            backgroundImage: "url('/assets/GraphicDesign.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                Graphic Design
              </Typography>
              </motion.div>

            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: 4,

            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          >
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,

                textTransform: "uppercase",
              }}
            >
              Elevate Your Brand with Stunning Visuals
            </Typography>
            </motion.div>

            {/* Divider Centered */}
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "40%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              "Our graphic design services help businesses create visually compelling and brand-focused designs that leave a lasting impression. We specialize in:",
              "Brand Identity & Logo Design – Crafting unique logos and branding materials.",
              "Marketing & Advertising Design – Designing eye-catching flyers, brochures, banners, and social media graphics.",
              "Website & UI/UX Design – Creating intuitive and aesthetically pleasing web and app interfaces.",
              "Packaging & Print Design – Designing product packaging, business cards, and promotional materials.",
              "Illustrations & Custom Artwork – Developing bespoke graphics and illustrations to enhance your brand.",
              "We combine creativity with strategy to ensure your visuals communicate effectively and stand out in any medium.",
            ].map((text, index) => (
              <motion.div initial="hidden"
               whileInView="visible" 
               variants={getAnimation(index + 5)}
               viewport={{ amount: 0.2 }}>
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
            ))}
          </Container>
        </Box>
      </Box>

      {/* Web Development Section */}

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },
            backgroundImage: `url('/assets/webd3.png')`,

            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                WEB DEVELOPMENT
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: 4,

            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,

                textTransform: "uppercase",
              }}
            >
              Our web development services focus on creating fast, secure, and
              visually stunning websites tailored to your business needs. We
              specialize in:
            </Typography>
            </motion.div>

            {/* Divider Centered */}
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "40%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              "Our web development services focus on creating fast, secure, and visually stunning websites tailored to your business needs. We specialize in:",
              "Custom Website Development – Building unique, fully customized websites from scratch.",
              "E-Commerce Development – Creating powerful online stores with secure payment integration.",
              "CMS Development – Developing and optimizing websites on platforms like WordPress, Shopify, and Webflow.",
              "Web Application Development – Building scalable, high-performance web apps.",
              "Ongoing Maintenance & Support – Keeping your site updated, secure, and running smoothly.",
              "Responsive Design – Ensuring seamless performance across all devices.",
              "We combine modern technologies with innovative design to help you stand out online. Let’s bring your vision to life!",
            ].map((text, index) => (
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(index +7)} viewport={{ amount: 0.2 }}>
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
            ))}
          </Container>
        </Box>
      </Box>

      {/* WhatsApp Business API Section with New Background */}

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },

            backgroundImage: "url('/assets/api.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                NATURAL LANGUAGE PROCESSING (NLP)
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: 4,

            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          >
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                letterSpacing: 2,
                marginBottom: 2,
                textTransform: "uppercase",
              }}
            >
              WhatsApp Business API
            </Typography>
            </motion.div>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,

                textTransform: "uppercase",
              }}
            >
              Our Natural Language Processing (NLP) services help businesses
              leverage AI to understand, analyze, and generate human language.
              We specialize in:
            </Typography>
            </motion.div>

            {/* Divider Centered */}
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "40%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              " Text Analysis & Sentiment Detection – Extracting insights from customer feedback, reviews, and social media.",
              "Chatbots & Virtual Assistants – Developing AI-powered conversational agents for customer support and automation.",
              "Speech Recognition & Processing – Converting spoken language into text for transcription and voice commands.",
              "Machine Translation – Enabling accurate, real-time translation across multiple languages.",
              "Automated Content Generation – Creating AI-driven text summaries, reports, and responses.",
              "We use cutting-edge AI and machine learning to help businesses enhance communication, automate workflows, and gain valuable insights from text and speech data.",
            ].map((text, index) => (
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(index + 5)} viewport={{ amount: 0.2 }}>
                <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
            ))}
          </Container>
        </Box>
      </Box>

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },

            backgroundImage: `url('/assets/Email.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Email Marketing Services
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          >
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                letterSpacing: 2,
                textTransform: "uppercase",
              }}
            >
              Our email marketing services help businesses connect with their
              audience, nurture leads, and drive conversions through targeted
              email campaigns. We specialize in:
            </Typography>
            </motion.div>

            {/* Divider Centered */}
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{
                  marginTop: 0,
                  marginBottom: 5,
                  bgcolor: "black",
                  height: 2,
                  width: "40%",
                  maxWidth: "500px",
                  mt: 3,
                }}
              />
            </Box>
            {[
              "Campaign Strategy & Design – Crafting compelling email content with eye-catching designs.",
              "Automated Email Sequences – Setting up drip campaigns, welcome emails, and follow-ups for engagement.",
              "Personalization & Segmentation – Tailoring emails based on customer behavior and preferences.",
              "Newsletter Creation – Developing informative and visually appealing newsletters.",
              "Analytics & Optimization – Tracking performance and optimizing campaigns for higher open and click-through rates.",
              "We help you build strong customer relationships and maximize your marketing ROI with effective, data-driven email campaigns.",
            ].map((text, index) => (
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(index +5)} viewport={{ amount: 0.2 }}>
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
              
            ))}
          </Container>
        </Box>
      </Box>

      <Box>
        {/* Top Fullscreen Image */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "100vh", lg: "100vh" },
            marginBottom: 0,
            backgroundImage: `url('/assets/socialm.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  spacing: 3,
                  marginTop: "28%",
                  color: "white",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Social Media Management
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            //padding: 4,

            backgroundColor: "white",
            color: "black",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ textAlign: "center", alignItems: "center", py: 8 }}
          >
            {[
              "Scheduling: Planning and automating posts to go live at optimal times.",
              "NLP Service -  Responding to comments, messages, and fostering engagement with followers.",
              "Analytics & Reporting: Tracking performance metrics to assess success and optimize strategies.",
              "Paid Advertising: Managing paid ad campaigns to drive growth and traffic.",
              "The goal is to enhance brand awareness, increase customer engagement",
            ].map((text, index) => (
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  letterSpacing: 1,
                  fontSize: 15,
                }}
              >
                {text}
              </Typography>
              </motion.div>
            ))}
          </Container>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: 0,
          marginBottom: 5,
          backgroundColor: "white",
          minHeight: "60vh",
          marginLeft: { xs: 0, sm: 8 },
          marginRight: { xs: 0, sm: 8 },
        }}
      >
        {/* White border on the left */}

        <Container
          maxWidth="md"
          sx={{ textAlign: "center", alignItems: "center", py: 8 }}
        ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h2"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              spacing: 1,
              marginTop: 0,
              color: "black",

              textTransform: "uppercase",
              fontSize: { xs: "3rem", sm: "3.8rem" },
            }}
          >
            PACKAGES
          </Typography>
          </motion.div>

          <Box display="flex" justifyContent="center">
            <Divider
              sx={{
                marginTop: 0,

                bgcolor: "black",
                height: 2,
                width: "40%",
                maxWidth: "500px",
                mt: 3,
              }}
            />
          </Box>
        </Container>
        {/* Grid Container */}
        <Container sx={{ minHeight: "80vh", height: "100%" }}>
        <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
          <Grid container spacing={4}>
            {packages.map((pkg, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="535"
                    image={pkg.image}
                    alt={`Package ${index + 1}`}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          </motion.div>
        </Container>
      </Box>
    </Box>
    </div>
  );
}
