
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

import { Link } from 'react-router-dom'; 
const menuItems = [
  { label: "Home", path: "/" },
  { label: "About Us", path: "/about" },
  { label: "Services", path: "/services" },
  
     { label: "Our Work", path: "/our-work" },
     { label: "Blog", path: "/blog" },
  { label: "Contact", path: "/contact" },
];

const MenuOptionList = ({ handleClose }) => {
  return (
    
    <List style={{ marginTop: '40px', }}>
      {menuItems.map((item) => (
        <ListItem button key={item.path} onClick={handleClose}
        style={{ marginTop: '10px', cursor: 'pointer',alignItems: 'center' }} 
        >
          {/* Use Link for navigation */}
          <Link to={item.path} style={{ textDecoration: 'none', color: 'black' }}>
            <ListItemText primary={item.label} 
              primaryTypographyProps={{
                sx: {
                  fontSize: '1.3rem', // Ensure this applies
              
                  textTransform: 'uppercase',
                  color: 'black',
                  fontWeight: 500,
                  letterSpacing: '0.1em',
                },
              }}
            
            />
          </Link>
        </ListItem>
      ))}
    </List>

  );
};

export default MenuOptionList;
