import React, { useState, useEffect, useRef } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

import { useNavigate } from "react-router-dom";


import { motion } from "framer-motion";

// Styles for the hero sections
const HeroSection = styled("section")({
  position: "relative",
  height: "100vh", 
  width: "100%",
  overflow: "hidden",
  scrollSnapAlign: "start",
  scrollSnapStop: "normal", 
});


// const MediaBackground = styled("div")({
//   position: "absolute",
//   top: 0,
//   left: 0,
//   width: "120%",
//   height: "auto",
//   maxHeight: "900px",
//   minHeight: "140%",
//   objectFit: "cover",
//   zIndex: -1,
// });
const VideoBackground = styled("video")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "120%",
  // width: "100%",
 // height: "100%",
  height: "auto",
 maxHeight: "900px",
 minHeight: "140%",
  objectFit: "cover",
  
  zIndex: -1,
});

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1,
});

const ContentWrapper = styled(Container)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: "#fff",
  zIndex: 2,
});

// Styles for the navigation dots
const DotNav = styled("div")({
  position: "fixed",
  top: "50%",
  left: 0,
  transform: "translateY(-50%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  zIndex: 3,
  marginLeft: "2rem", 
});

const DotWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "15px",
});

const Circle = styled("div")({
  width: "18px", 
  height: "18px",
  borderRadius: "50%",
  border: "2px solid transparent", 
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "5px", 
  transition: "all 0.3s ease", 
  "&.active": {
    borderColor: "#fff", 
  },
});

const Dot = styled("div")({
  width: "8px", // Dot size
  height: "8px", // Dot size
  borderRadius: "50%",
  backgroundColor: "#fff",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#ddd",
  },
  "&.active": {
    backgroundColor: "#ddd", // Change color for active dot
  },
});

export default function LandingPage() {
  const [activeSection, setActiveSection] = useState(0);

  const navigate = useNavigate();


  const [scrollDirection, setScrollDirection] = useState("down");

  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);

  // Animation variants based on scroll direction
 


  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.5, duration: 0.8, ease: "easeOut" }, // ✅ Added more delay
    },
  });
  

  // Use refs for each section to observe their visibility
  const sectionRefs = useRef([]);

  // Handle dot click
  const handleDotClick = (index) => {
    setActiveSection(index);
    const section = document.getElementById(`section-${index}`);
    section.scrollIntoView({ behavior: "smooth" });
  };

  // Intersection Observer callback
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = sectionRefs.current.indexOf(entry.target);
        setActiveSection(index);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, 
    });

    
    const currentRefs = sectionRefs.current;

    // Observe valid elements
    currentRefs.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    // Cleanup observer on component unmount
    return () => {
      currentRefs.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div
    id="trigger-section"
    style={{
      
      minHeight: "100vh",
      
    }}
  >
    <div
      style={{
        height: "100vh",
        overflowY: "scroll",
        scrollSnapType: "y mandatory", 
        WebkitOverflowScrolling: "touch", 
        scrollbarWidth: "none", 
      }}
    >
      {/* Hero Section 1 */}
      <HeroSection id="section-0" ref={(el) => (sectionRefs.current[0] = el)}>
        <VideoBackground autoPlay loop muted playsInline>
          <source src="/assets/video33.mp4"  type="video/mp4" />
          Your browser does not support the video tag
        </VideoBackground>
        <Overlay />
        <ContentWrapper maxWidth="md">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              
              <Typography
                variant="h6"
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontFamily: "Century Gothic",
                  fontSize: { xs: "1rem", sm: "1.3rem" },
                }}
              >
                Megaleio Advertising Agency
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, fontFamily: "Century Gothic",fontSize: { xs: "3rem", sm: "3.8rem" } }}
              >
                GROWTH STRATEGISTS
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1"></Typography>
            </Grid>
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  borderRadius: "30px",
                  padding: { xs: "8px 20px", sm: "10px 30px" },
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontFamily: "Century Gothic",
                  "&:hover": { backgroundColor: "#ddd" },
                }}
                onClick={() => navigate("/about")}
              >
                Learn More
              </Button>
              </motion.div>
            </Grid>
          </Grid>
        </ContentWrapper>
      </HeroSection>

      {/* Hero Section 2 */}
      <HeroSection id="section-1" ref={(el) => (sectionRefs.current[1] = el)}>
         <VideoBackground autoPlay loop muted playsInline>
          /assets/video2.mp4
          <source src="/assets/video2.mp4" type="video/mp4" />
          Your browser does not support the video tag
        </VideoBackground> 

{/*         
          <MediaBackground
          
          sx={{
            width: "100%",
            height: { xs: "100vh", sm: "100vh", md: "100vh" }, // Ensure full viewport height across devices
            //position: "relative",
            //maxWidth:{xs:"600px",},
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden"
          }}>
            <img src="/assets/home1.png" alt="Video Background 1" style={{ width: "100%",
             height: "100%", objectFit: "cover" , // Ensures the image covers the entire area
      objectPosition: "center",}} />
          </MediaBackground> */}
        <Overlay />
        <ContentWrapper maxWidth="md">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, fontFamily: "Century Gothic",fontSize: { xs: "3rem", sm: "3.8rem" } }}
              >
                WE DESIGN IMPACT
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  letterSpacing: 2,
                  fontFamily: "Century Gothic",
                }}
              >
                “Driving digital transformation with innovative solutions”
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  borderRadius: "30px",
                  padding: { xs: "8px 20px", sm: "10px 30px" },
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontFamily: "Century Gothic",
                  "&:hover": { backgroundColor: "#ddd" },
                }}
                onClick={() => navigate("/services")}
              >
                Discover More
              </Button>
              </motion.div>
            </Grid>
          </Grid>
        </ContentWrapper>
      </HeroSection>

      {/* Hero Section 3 */}
      <HeroSection id="section-2" ref={(el) => (sectionRefs.current[2] = el)}>
        <VideoBackground autoPlay loop muted playsInline>
          <source src="/assets/HomeVideo.mp4"type="video/mp4" />
          Your browser does not support the video tag
        </VideoBackground> 
{/* 
<MediaBackground

sx={{
  width: "100%",
  height: { xs: "100vh", sm: "100vh", md: "100vh" }, // Ensure full viewport height across devices
  //position: "relative",

  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden"
}}>
            <img src="/assets/home3.png" 
            
            alt="Video Background 1" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </MediaBackground> */}

        
        <Overlay />
        <ContentWrapper maxWidth="md">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{ fontWeight: 600,fontFamily: "Century Gothic", fontSize: { xs: "3rem", sm: "3.8rem" } }}
              >
                WE DELIVER RESULTS
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>

            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
              <Typography variant="subtitle1" sx={{fontFamily: "Century Gothic", letterSpacing: 2 }}>
                “Our marketing strategies bring measurable outcomes”
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
            <Button
                variant="contained"
                size="large"
                sx={{
                  borderRadius: "30px",
                  padding: { xs: "8px 20px", sm: "10px 30px" },
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontFamily: "Century Gothic",
                  "&:hover": { backgroundColor: "#ddd" },
                }}
                onClick={() => navigate("/contact")}
              >
                 Get Started
              </Button>
              </motion.div>
              
            </Grid>
          </Grid>
        </ContentWrapper>
      </HeroSection>

      {/* Dot Navigation */}
      <DotNav>
        {sectionRefs.current.map((_, index) => (
          <DotWrapper key={index}>
            <Circle className={activeSection === index ? "active" : ""}>
              <Dot
                className={activeSection === index ? "active" : ""}
                onClick={() => handleDotClick(index)}
              />
            </Circle>
          </DotWrapper>
        ))}
      </DotNav>
    </div>
    </div>
  );
}


