import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom"; 

import { motion } from "framer-motion";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const SubscribePopUp = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation(); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 3000); 

    return () => clearTimeout(timer);
}, [location.pathname]); 

  const handleClose = () => setOpen(false);
  const handleSubscribe = async () => {
    if (!email) {
      setMessage("Please enter a valid email.");
      return;
    }
    if (loading) return;
    setLoading(true);
  
    try {
        
      const response = await fetch("http://localhost:5000/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
  
      const result = await response.json();
      setMessage(result.message);
    } catch (error) {
      setMessage("Error: " + error.message);
    }
    setLoading(false);
  };

  //const handleSubscribe = async () => {
//     if (!email) {
//       setMessage("Please enter valid email.");
//       return;
//     }
//     setLoading(true);

//     const mailchimpUrl ="https://us12.api.mailchimp.com/3.0/lists/c418566d84/members";

//     const data = {
//       email_address: email,
//       status: "Subscribed",
//     };

//     try {
//       const response = await fetch(mailchimpUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: " Basic " + btoa("f29cfef6d1823b7d65604aea1e4ff3b8-us12"),
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.status === 200) {
//         setMessage("Thank You For Subscribing!");
      
//         setEmail("");
//       } else {
//         setMessage(" Subscription Failed. Try Again");
//       }
//     } catch (error) {
//       setMessage("Error:" + error.message);
//     }
//     setLoading(false);
//   };

  //


  const [scrollDirection, setScrollDirection] = useState("down");
  
    // Detect scroll direction
    useEffect(() => {
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        setScrollDirection(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY;
      };
  
      window.addEventListener("scroll", updateScrollDirection);
      return () => window.removeEventListener("scroll", updateScrollDirection);
    }, []);
  
    // Animation variants based on scroll direction
   
  
  
    const getAnimation = (index) => ({
      hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { delay: index * 0.5, duration: 0.8, ease: "easeOut" }, // ✅ Added more delay
      },
    });
    
  
  return (
    <Box >
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
        sx:{
          position: "fixed",
          //bottom: 50,
          right: 20,
          bottom: { xs: "100px", sm: "80px" },
          width: { xs: "80%", sm: "440px" }, 
          minHeight: { xs: "300px", sm: "220px" },
          zIndex: 9999,
          padding: 2,
        
          '&.MuiDialog-paper':{
            minHeight:'80px'
          }
        }
    }}
      >
        <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
                   
        <DialogTitle
        sx={{
            marginTop:2
        }
          } 
        >
          Subscribe To Our Weekly News Letter
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        </motion.div>
        
        <DialogContent>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
                         
          <TextField
            label="Your email"
            variant="outlined"
            fullWidth
            
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
                marginTop:2,
              marginBottom: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "black", // Black border on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black", // Black border when focused
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black", // Label color when focused
              },
            }}
          />
          </motion.div>
          
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            onClick={handleSubscribe}
            
            sx={{
                backgroundColor: "black", 
                color: "white", 
                "&:hover": {
                  backgroundColor: "white",
                  color: "black", 
                  border: "1px solid black", 
                },
              }}
          >
            {loading ? "Subscribing..." : "Subscribe"}
          </Button>
         
          {message && (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              {message}
            </p>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default SubscribePopUp;
