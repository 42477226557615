import React, { useState } from "react";
import { Fab,Tooltip, Typography, Box  } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const FloatingWhatsAppButton = () => {
  const phoneNumber = "+27817411823"; 
  const [open, setOpen] = useState(false);

  return (



    <Tooltip
    title={
      <Box sx={{ p: 1 }}>
        <Typography variant="body2">Chat with us on WhatsApp!</Typography>
      </Box>
    }
    open={open}
    onMouseEnter={() => setOpen(true)}
    onMouseLeave={() => setOpen(false)}
    arrow
  >
    <Fab
      color="success"
      aria-label="WhatsApp"
      onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
      sx={{
        position: "fixed",
        bottom: 40,
        right: 40,
        zIndex: 9999,
        backgroundColor: "#25D366",
        "&:hover": {
          backgroundColor: "#1EBE5D",
        },
      }}
    >
      <WhatsAppIcon sx={{ color: "white", fontSize: 30 }} />
    </Fab>
  </Tooltip>
    
  );
};

export default FloatingWhatsAppButton;
