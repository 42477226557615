import React from 'react';
import Header from './components/Header.jsx';
import Footer from './components/footer.jsx';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import ServicePage from './pages/services.jsx';
import ContactForm from './pages/contact.jsx';
import AboutUs from './pages/aboutus.jsx';
import Home  from './pages/Home.jsx';

import Blog from './pages/Blog.jsx';
import FloatingWhatsAppButton from './pages/Whatsapp.jsx';
import OurWork from './pages/OurWork.jsx';
import './index.css';
import SubscribePopUp from "./pages/popup.jsx";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Century Gothic, sans-serif",
  },
});
function App() {
  return (
    <div className="App"  
    style={{display: 'flex', 
      flexDirection: 'column', 
      
       
    

     }}> 
          <ThemeProvider theme={theme}>
      {/* Your App Components */}
   
      <Header/>
      <SubscribePopUp/>
      <FloatingWhatsAppButton />
      <div style={{ flex: 1 , marginTop: "-110px" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/our-work" element={<OurWork />} />
        
         <Route path="/blog" element={<Blog/>} /> 
        <Route path="/contact" element={<ContactForm />} />
        
      </Routes>
      </div>
      <Footer/>
      </ThemeProvider>
    </div>
  );
}

export default App;

