import React from "react";
import { Box
} from "@mui/material";

import Ourservices from "../homepages/ourservices";

import LandingPage from "../homepages/Landingpage";
const Home = () => {
  return (
    <Box sx={{ width: "100%", scrollBehavior: "smooth",   scrollSnapType: "y mandatory",  // Enabling scroll snapping
      WebkitOverflowScrolling: "touch",  // For smooth scrolling on iOS
    }}
  >
  <div
  id="trigger-section"
  style={{
    // Full content height and a neutral background for visualization
    //minHeight: "100vh",
    //backgroundColor: "lightgray",
  }}
>
    {/* Landing Page Section */}
    <div style={{ //minHeight: "120vh",
     paddingTop: "100px",
       scrollSnapAlign: "start" }}>
      <LandingPage />
    </div>

    {/* Our Services Section */}
    <div style={{ scrollSnapAlign: "start" }}>
      <Ourservices />
    </div>
      </div>
    </Box>
   
  );
};

export default Home;
