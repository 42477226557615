
import React from "react";
import { Box, Grid, Typography, Container, Divider } from "@mui/material";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
export default function OurWork() {

  const [scrollDirection, setScrollDirection] = useState("down");
    
      // Detect scroll direction
      useEffect(() => {
        let lastScrollY = window.scrollY;
    
        const updateScrollDirection = () => {
          const scrollY = window.scrollY;
          setScrollDirection(scrollY > lastScrollY ? "down" : "up");
          lastScrollY = scrollY;
        };
    
        window.addEventListener("scroll", updateScrollDirection);
        return () => window.removeEventListener("scroll", updateScrollDirection);
      }, []);
    
      // Animation variants based on scroll direction
      const getAnimation = (index) => ({
        hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
        visible: {
          opacity: 1,
          y: 0,
          transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
        },
      });
    
  return (
    <div
  id="trigger-section"
  style={{
    // Full content height and a neutral background for visualization
    minHeight: "100vh",
    //backgroundColor: "lightgray",
  }}
>
    <Box
      sx={{
        
         
          minHeight: "100vh", 
          overflowY: "scroll",
        
      }}
    >
      {/* Image on Top */}

      <Box
        sx={{
          minHeight: { xs: "115vh", sm: "130vh", md: "130vh", lg: "130vh" },
           //minHeight: { xs: "105vh", sm: "110vh", md: "110vh", lg: "110vh" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",

          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          animation: "moveBackground 30s linear infinite",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: 2,
                textTransform: "uppercase",
                fontSize: { xs: "1rem", sm: "1.3rem" },
              }}
            >
              Megaleio Advertising Agency
            </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, fontSize: { xs: "3rem", sm: "3.8rem" } }}
            >
              OUR WORK
            </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
      {/* Client 1 - Dadas Motorland */}
      <Box>
        <Box
          sx={{
            width: "100%",
            height: { xs: "30vh", sm: "30vh", md: "100vh", lg: "100vh" },
            backgroundImage: `url('/assets/dadas.jpg')`,
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        ></Box>
        <Box
          sx={{
            width: "100vw",
            height:"40vh",
            overflowX: "hidden",
            //overflowY: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(51, 51, 51, 0.2)",
           
            backgroundImage: "url(assets/servicebg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            animation: "moveBackground 30s linear infinite",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontSize: { xs: "1rem", sm: "1.3rem" },
                }}
              >
                Dadas Motorland
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Project
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                2018 - 2020 
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Container
          maxWidth="md"
          sx={{ textAlign: "center", alignItems: "center", py: 8 }}
        ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              letterSpacing: 2,
              textTransform: "uppercase",
            }}
          >
            How Strategic Digital Marketing Accelerated Dadas Motorland’s Luxury
            Sales
          </Typography>
          </motion.div>

          {/* Divider Centered */}
          <Box display="flex" justifyContent="center">
            <Divider
              sx={{
                marginTop: 0,
                marginBottom: 5,
                bgcolor: "black",
                height: 2,
                width: "40%",
                maxWidth: "500px",
                mt: 3,
              }}
            />
          </Box>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            As the digital marketing partner for Dadas Motorland—Benoni’s
            premier destination for exotic and luxury vehicles—our goal was to
            elevate their online presence to match the prestige of their
            high-end inventory. While the dealership boasted an impressive fleet
            of rare and sought-after cars, their digital footprint struggled to
            keep pace. Here’s how we transformed their visibility, engagement,
            and sales in a competitive luxury market:
          </Typography>
          </motion.div>
          

          
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              textTransform: "uppercase",
              letterSpacing: 1,
              marginBottom: 1,
              fontWeight: "bold",
            }}
          >
            The Challenge
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
          >
            Dadas Motorland faced three critical hurdles:
          </Typography>
          </motion.div>
          
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
          >
            <strong>Stagnant Social Media:</strong>
            
            Inconsistent posting, low engagement, and delayed responses to
            inquiries were costing them potential buyers.
         
            <Box sx={{ marginBottom: 2 }} />
            <strong>Outdated Online Presence:</strong>
            
            Their website lacked modern appeal, and their Google My Business
            (GMB) profile was underutilized, making it hard for luxury car
            enthusiasts to find them.
            <br />
            <Box sx={{ marginBottom: 2 }} />
            <strong>Branding Gaps:</strong>
            
            They needed eye-catching billboard designs and cohesive visuals to
            reinforce their premium identity in physical and digital spaces.
            <br />
            <Box sx={{ marginBottom: 2 }} />
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(5)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 16,
              textTransform: "uppercase",
              letterSpacing: 1,
              marginBottom: 1,
              fontWeight: "bold",
            }}
          >
            The Strategy
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(6)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
          >
            <strong>Revitalizing Social Media & Customer Experience</strong>
            <br />
            Took full ownership of their Facebook and Instagram accounts,
            curating a content calendar that blended aspirational car showcases,
            behind-the-scenes exclusives, and client testimonials. Implemented
            rapid-response protocols for inquiries, ensuring prompt,
            professional communication aligned with their luxury brand ethos.
            <br />
            <Box sx={{ marginBottom: 2 }} />
            <strong>Google My Business & Website Optimization</strong>
            <br />
            Overhauled their GMB profile with high-resolution images, updated
            service details, and keyword-rich descriptions to dominate local
            “luxury car dealership” searches. Modernized their website UX/UI for
            seamless browsing, emphasizing speed, elegance, and clear
            calls-to-action (e.g., “Schedule a Test Drive”).
            <br />
            <Box sx={{ marginBottom: 2 }} />
            <strong>Targeted Paid Advertising</strong>
            <br />
            Launched hyper-focused Google Ads campaigns targeting high-net-worth
            audiences searching for terms like “exotic cars in Benoni” or
            “luxury SUV dealerships.” Ran visually striking Facebook/Instagram
            Ads showcasing limited-edition vehicles, retargeting engaged users
            with tailored offers.
            <br />
            <Box sx={{ marginBottom: 2 }} />
            <strong>Premium Billboard Design</strong>
            <br />
            Created bold, minimalist billboards that mirrored the sophistication
            of their inventory, using sleek visuals and taglines like “Where
            Luxury Meets Legacy” to captivate drivers in high-traffic areas.
          </Typography>
          </motion.div>
          
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(7)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 16,
              textTransform: "uppercase",
              letterSpacing: 1,
              marginBottom: 1,
              fontWeight: "bold",
            }}
          >
            The Results
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(8)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
          >
            <strong>Audience Growth:</strong>Social media followers surged by
            150%, with heightened engagement from luxury buyers and collectors.
            <br />
            <strong>Sales Momentum:</strong> A 40% increase in test drive
            bookings and a notable uptick in closed deals, particularly for
            high-value models.
            <br />
            <strong>Brand Authority:</strong>
            Billboards became local landmarks, while their optimized GMB profile
            now ranked #1 for key luxury car searches in Gauteng.
          </Typography>
          </motion.div>
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(9)} viewport={{ amount: 0.2 }}>

          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              textTransform: "uppercase",
              letterSpacing: 1,
              marginBottom: 1,
              fontWeight: "bold",
            }}
          >
            Why It Matters:
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(10)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            For a dealership like Dadas Motorland, every detail—from a
            pixel-perfect Instagram post to a billboard on the N12 highway—must
            scream exclusivity. By merging data-driven digital tactics with
            premium branding, we positioned them not just as a car seller, but
            as a curator of automotive dreams. Their success story proves that
            even in elite markets, the right digital strategy can shift gears
            from static to spectacular.
          </Typography>
          </motion.div>
        </Container>
      </Box>

      {/* Client 2 - Sun International Times Square */}
      <Box>
      <Box
          sx={{
            width: "100%",
          
            backgroundImage: `url('/assets/thesun.jpg')`,
            height: { xs: "30vh", sm: "30vh", md: "100vh", lg: "100vh" },
            
            backgroundSize: "contain",
           
            backgroundPosition: "center",
          }}
        ></Box>
      <Box
        sx={{
          width: "100vw",
          height: "40vh",
          overflowX: "hidden",
          //overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",
          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: 2,
                textTransform: "uppercase",
                fontSize: { xs: "1rem", sm: "1.3rem" },
              }}
            >
              Sun International Times Square | Sun City
            </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 400,
                textTransform: "uppercase",
                fontSize: { xs: "3rem", sm: "3.8rem" },
              }}
            >
              Set Builder Activation Service
            </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Container
          maxWidth="md"
          sx={{ textAlign: "center", alignItems: "center", py: 8 }}
        ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              letterSpacing: 2,
              textTransform: "uppercase",
            }}
          >
            Crafting Unforgettable Holiday Experiences at Times Square
          </Typography>
          </motion.div>

          {/* Divider Centered */}
          <Box display="flex" justifyContent="center">
            <Divider
              sx={{
                marginTop: 0,
                marginBottom: 5,
                bgcolor: "black",
                height: 2,
                width: "40%",
                maxWidth: "500px",
                mt: 3,
              }}
            />
          </Box>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            Times Square sought to transform its bustling visitor spaces into
            immersive seasonal wonderlands during Christmas and Easter,
            fostering deeper connections with families and creating shareable
            moments.
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            <strong>For Christmas,</strong> we designed an enchanting Santa
            Village that brought festive magic to life. Visitors explored a
            whimsical winter-themed hub featuring interactive activities like
            holiday face painting, storytelling sessions with Santa, and a
            sparkling photo booth adorned with seasonal props. Families left
            with cherished keepsakes and memories of laughter, joy, and
            togetherness.
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            <strong>At Easter,</strong> we reimagined the spirit of spring with
            a vibrant, pastel-filled activation. A playful Easter Bunny roamed
            the plaza, posing for heartwarming photos with families, while a
            themed scavenger hunt encouraged kids to explore the space. The
            result? A joyful, Instagram-worthy celebration that turned casual
            visitors into enthusiastic brand ambassadors.
          </Typography>
          </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 15, letterSpacing: 1 }}
          >
            Both campaigns elevated Times Square’s reputation as a destination
            for immersive seasonal experiences, driving foot traffic, social
            engagement, and lasting emotional resonance.
          </Typography>
          </motion.div>
        </Container>
      </Box>
      </Box>

      {/* Client 3 - Unjani Clinics */}

      <Box>
        {/* Top Fullscreen Image */}

        <Box
          sx={{
            width: "100%",
            
            backgroundImage: `url('/assets/Unjani.jpg')`,
           
            height: { xs: "30vh", sm: "30vh", md: "100vh", lg: "100vh" },
            
            backgroundSize: "contain",
            
            backgroundPosition: "center",
          }}
        ></Box>
        <Box
          sx={{
            width: "100vw",
            height: "40vh",
            overflowX: "hidden",
            //overflowY: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(51, 51, 51, 0.2)",
            backgroundImage: "url(assets/servicebg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontSize: { xs: "1rem", sm: "1.3rem" },
                }}
              >
                Unjani Clinics
              </Typography>
              </motion.div>
            </Grid>

            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Project
              </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                2020 - ongoing
              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>



        <Box sx={{ padding: 4, margin: 0 }}>
          <Grid
            container
            spacing={1}
            sx={{
              minHeight: "40vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Description */}
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              sx={{ paddingX: 4, marginTop: 5, textAlign: "center" }}
            ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  letterSpacing: 2,

                  textTransform: "uppercase",
                }}
              >
                How Digital Marketing Transformed Unjani Clinics’ Community
                Impact
              </Typography>
              </motion.div>

              <Box display="flex" justifyContent="center">
                <Divider
                  sx={{
                    marginTop: 0,
                    marginBottom: 5,
                    bgcolor: "black",
                    height: 2,
                    width: "40%",
                    maxWidth: "500px",
                    mt: 3,
                  }}
                />
              </Box>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: 16,
                  letterSpacing: 1,
                  marginBottom: 3,
                  fontWeight: 400,
                }}
              >
                As a digital marketer partnering with Unjani Clinics—a network
                of nurse-owned healthcare providers in high-density South
                African communities—our mission was clear: amplify their reach
                and impact. These clinics deliver affordable, efficient private
                healthcare, reducing pressure on public systems, yet many
                struggled with low visibility and foot traffic despite their
                critical role. Here’s how I helped them pivot from overlooked to
                indispensable:
              </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(5)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 18,
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  marginBottom: 1,
                  fontWeight: "bold",
                }}
              >
                The Challenge
              </Typography>
              </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(6)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
              >
                Unjani Clinics needed to stand out in crowded urban areas. Their
                life-changing services—fast, reliable, and affordable care—were
                often underutilized due to limited brand awareness and a lack of
                cohesive online presence.
              </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(7)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  marginBottom: 1,
                  fontWeight: "bold",
                }}
              >
                The Strategy
              </Typography>
              </motion.div>
              
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(8)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
              >
                <strong>Building a Professional Digital Foundation</strong>
                <br />
                We crafted tailored social media profiles (Facebook, Instagram)
                for each clinic, designing logos, profile images, and cover
                photos that reflected their commitment to compassionate care.
                This created visual consistency and instilled trust at first
                glance.
                <br />
                <Box sx={{ marginBottom: 2 }} />
                <strong>Google Optimization for Local Discovery</strong>
                <br />
                Many clinics were virtually invisible online. We optimized their
                Google My Business listings, ensuring accurate locations,
                services, and contact details, making it effortless for locals
                to find them.
                <br />
                <Box sx={{ marginBottom: 2 }} />
                <strong>Social Media Campaigns That Educate & Engage</strong>
                <br />
                Through targeted posts, we highlighted their services (e.g.,
                primary care, maternal health), transparent pricing, and patient
                testimonials. Regular updates kept the Unjani brand top-of-mind,
                positioning clinics as approachable, community-first healthcare
                partners.
                <br />
                <Box sx={{ marginBottom: 2 }} />
                <strong>Community-Centric Storytelling</strong>
                <br />I showcased the nurses’ expertise and patient success
                stories, humanizing the clinics and fostering emotional
                connections. This built credibility and encouraged word-of-mouth
                referrals.
              </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(9)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  marginBottom: 1,
                  fontWeight: "bold",
                }}
              >
                The Results
              </Typography>
              </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(10)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
              >
                <strong>Audience Growth:</strong> Many clinics now boast
                thousands of engaged social media followers—a direct line to
                inform and connect with their communities.
                <br />
                <strong>Foot Traffic Surge:</strong> Increased visibility
                translated to busier clinics, with loyal patients returning for
                routine care and recommending services to others.
                <br />
                <strong>Sustainable Impact:</strong> By merging healthcare
                excellence with digital savvy, Unjani Clinics are now thriving
                hubs, proving affordable care can be both accessible and
                scalable.
              </Typography>
              </motion.div>
<motion.div initial="hidden" whileInView="visible" variants={getAnimation(11)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  marginBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Why It Matters
              </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(12)} viewport={{ amount: 0.2 }}>

              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: 15, letterSpacing: 1, marginBottom: 3 }}
              >
                This work isn’t just about metrics—it’s about ensuring
                life-saving care reaches those who need it most. By empowering
                nurse entrepreneurs with digital tools, we’ve helped Unjani
                Clinics become pillars of health equity, one community at a
                time.
              </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(13)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{ 
                  fontSize: 16,
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  marginTop:5,
                  fontWeight: "bold",
                  
                  marginBottom: 3 }}
              >



              How can we create your story?

              </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </Box>

    </Box>
    </div>
  );
}
