import React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download"; // Import download icon

import { motion } from "framer-motion";
const blogPosts = [
  {
    title: "AI-Powered Chatbots for Private Healthcare in South Africa: A Quick Guide",
    description:" AI-powered chatbots are transforming private healthcare in South Africa. This presentation provides a quick guide on how these chatbots work, the key benefits they offer, and practical tips for successful implementation. Discover how AI can enhance patient care, reduce operational costs, and improve overall efficiency in your clinic or hospital.",
      image: "/assets/aficanwoman.png",
      pdf: "/assets/AI-Powered-Chatbots-for-Private-Healthcare-in-South-Africa-A-Quick-Guide.pdf",
  },
 
  {
    title: "AI Chatbots: Supercharging What's app | Facebook Messenger | Instagram & Email Marketing",
    description:
      "Discover how AI chatbots are revolutionising Digital marketing. Learn how they build high-quality mailing lists, engage visitors, and boost ROI. Explore the pivotal role of AI in digital marketing success.",
    image: "/assets/blog2.png",
    pdf: "/assets/AI-Chatbots-Supercharging-Whats-app-or-Facebook-Messenger-or-Instagram-and-Email-Marketing.pdf",
  
  },
  {
    title: "Guide to Automating Social Media Responses & Driving Sales with ManyChat",
    description:"In today's fast-paced digital world, automating your social media responses and driving sales efficiently is crucial. ManyChat offers a robust solution to streamline customer interactions, engage your audience, and boost conversions through personalized conversations on platforms like Facebook Messenger, WhatsApp, and Instagram. This guide will walk you through setting up and leveraging ManyChat to optimize your social media strategy and achieve tangible sales results.",
      
      image: "/assets/blog3.png",
      pdf: "/assets/Guide-to-Automating-Social-Media-Responses-and-Driving-Sales-with-ManyChat.pdf",

  },
  {
    title: "Grassroots Marketing: Building Your Clinic in the Community",
    description:
      "A zero-budget approach to growing your healthcare practice through community connections. This strategy focuses on building trust and visibility where your patients live.",
    image: "/assets/blog1.png",
    pdf: "/assets/Grassroots-Marketing-Building-Your-Clinic-in-the-Community.pdf",
  },

  
];

const Blog = () => {
  const [scrollDirection, setScrollDirection] = useState("down");

  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);

  // Animation variants based on scroll direction
  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
    },
  });

  return (
    <div
  id="trigger-section"
  style={{
    // Full content height and a neutral background for visualization
    minHeight: "100vh",
    //backgroundColor: "lightgray",
  }}
>
    <Box sx={{ maginTop: 0, marginBottom: 8 }}>
      <Box
        sx={{
          marginBottom: 3,
          minHeight: { xs: "115vh", sm: "130vh", md: "130vh", lg: "130vh" },
          //minHeight: { xs: "105vh", sm: "110vh", md: "110vh", lg: "110vh" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",
          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",

          animation: "moveBackground 30s linear infinite",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={getAnimation(0)}
              viewport={{ amount: 0.2 }}
            >
              <Typography
                variant="h6"
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontSize: { xs: "1rem", sm: "1.3rem" },
                }}
              >
                Megaleio Advertising Agency
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={getAnimation(1)}
              viewport={{ amount: 0.2 }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                Blogs
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
      <Container
        sx={{
          minHeight: "80vh",
          height: "100%",
          marginTop: 10,
          //minHeight: "40vh",
        }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={getAnimation(0)}
          viewport={{ amount: 0.2 }}
        >
          <Typography
            variant="h6"
            align="center"
            sx={{
              letterSpacing: 1,
              textTransform: "uppercase",
              marginBottom: 10,
              fontWeight: 400,
              my: 4,
              color: "gray",
            }}
          >
            Latest news and case studies
          </Typography>
        </motion.div>
        <Grid container spacing={4}>
          {blogPosts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(0)}
                viewport={{ amount: 0.2 }}
              >
                <Card  height="800">
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={getAnimation(1)}
                    viewport={{ amount: 0.2 }}
                  >
                    <CardMedia
                      component="img"
                      height="300"
                      image={post.image}
                      alt={post.title}
                    />
                  </motion.div>
                  <CardContent>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={getAnimation(2)}
                      viewport={{ amount: 0.2 }}
                    >
                      <Chip
                        label="NEWS"
                        color="primary"
                        size="small"
                        sx={{ mb: 1 }}
                      />
                    </motion.div>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={getAnimation(3)}
                      viewport={{ amount: 0.2 }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{
                          letterSpacing: 1,
                          marginTop: 2,
                        }}
                      >
                        {post.title}
                      </Typography>
                    </motion.div>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={getAnimation(4)}
                      viewport={{ amount: 0.2 }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          letterSpacing: 1,
                          marginTop: 3,
                        }}
                      >
                        {post.description}
                      </Typography>
                    </motion.div>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={getAnimation(5)}
                      viewport={{ amount: 0.2 }}
                    >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DownloadIcon />}
                      href={post.pdf} // Set the PDF URL
                      download // Ensures the file is downloaded instead of opened
                      sx={{ marginTop: 2 ,
                        border: "1px solid primary", 
                     
                        "&:hover": {
                           color :"blue",
                           backgroundColor: "white", // Keeps background color on hover
                        
                      },

                      }}
                    >
                      Download PDF
                    </Button>
                    </motion.div>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
    </div>
  );
};

export default Blog;
