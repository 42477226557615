

import { useState, useEffect } from "react";
import { Box, Grid, Typography, Divider, Container } from "@mui/material";
import { motion } from "framer-motion";

import { Card, CardMedia } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";


const clients = [
  { image: "/assets/VHUMPHO-modified.png" },
  { image: "/assets/Unjani-modified.png" },
  { image: "/assets/Sun-modified.png" },
  { image: "/assets/Simu-modified.png" },
  { image: "/assets/Shekinah-modified.png" },
  { image: "/assets/Mzansi-modified.png" },
  { image: "/assets/Lochlogan-modified.png" },
  { image: "/assets/DADAS-modified.png" },
  { image: "/assets/mte-modified.jpg" },
];

export default function AboutSection() {
  const [scrollDirection, setScrollDirection] = useState("down");

  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);

  // Animation variants based on scroll direction
  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
    },
  });

  const paragraphs = [
    "At MAA, we specialize in transforming your digital footprint into a powerful beacon that captures attention and drives engagement. Your business deserves more than just visibility—it deserves to be seen, remembered, and trusted.",
    "We begin by crafting a dynamic online presence, starting with a sleek, user-centric website that serves as your digital storefront. From there, we amplify your reach through strategic social media management, ensuring your brand’s voice resonates authentically across platforms like Facebook, Instagram, LinkedIn, and beyond.",
    "Our expertise extends to targeted online search campaigns designed to position your business at the forefront of your industry. Paired with high-impact content tailored to your audience’s needs—from blogs and videos to SEO-optimized copy—we turn casual browsers into loyal customers.",
    "Every pixel, post, and keyword is meticulously curated to reflect your brand’s essence, values, and goals. We don’t just build online presences—we architect digital experiences that inspire action, foster connections, and elevate your market position.",
    "Let us give your business the eyes, engagement, and authority it needs to thrive in a competitive world. Together, we’ll ensure your brand isn’t just seen—it’s unforgettable.",
    "Your Vision. Our Expertise. Amplified Results.",
    "We look forward to your stay.",
  ];

  return (
    <>
      <div
      id="trigger-section"
      style={{
        
        minHeight: "100vh",
        
      }}
    >
      {/* Background Section */}
      <Box
        sx={{
          //height: "100vh",
          
          minHeight: { xs: "115vh", sm: "130vh", md: "130vh", lg: "130vh" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",
          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",


         
          
          animation: "moveBackground 30s linear infinite",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h6"
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontSize: { xs: "1rem", sm: "1.3rem" },
                }}
              >
                Megaleio Advertising Agency
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontSize: { xs: "3rem", sm: "3.8rem" },
                }}
              >
                About Us
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      {/* Image Section */}
      <Box
        sx={{
          backgroundImage: `url('/assets/newimagecontact.jpeg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "40vh", sm: "60vh", md: "70vh", lg: "80vh" },
          width: "100%",
          marginBottom: 3,
        }}
      />

      {/* Animated Text Section */}
      <Box sx={{ padding: 4, marginTop: 4 }}>
        <Grid container spacing={1} sx={{ minHeight: "40vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Container maxWidth="md" sx={{ textAlign: "center", alignItems: "center" }}>
            <Grid item xs={12}>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 400, marginBottom: 0 }}>
                  THIS IS WHO WE ARE
                </Typography>
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
                <Divider sx={{ mx: "auto", bgcolor: "black", height: 2, width: "30%", maxWidth: "500px", mt: 2 }} />
              </motion.div>
            </Grid>
          </Container>

          {/* Animated Paragraphs */}
          <Grid item xs={12} sm={8} md={8} sx={{ textAlign: "center", marginTop: 5 }}>
            {paragraphs.map((text, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(index + 4)}
                viewport={{ amount: 0.2 }}
              >
                <Typography variant="body1" paragraph sx={{ letterSpacing: 1, fontSize: 15, marginBottom: 3 }}>
                  {text}
                </Typography>
              </motion.div>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ maxWidth: 1300, width: "100%", mx: "auto", marginBottom: 4 }}>
      
        <Container
          maxWidth="md"
          sx={{ textAlign: "center", alignItems: "center", py: 4 }}
        >
          <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 400,
              textTransform: "uppercase",
              marginBottom: 1,
            }}
          >
            Our Clients
          </Typography>
          </motion.div>
          <Divider
            sx={{
              mx: "auto",
              bgcolor: "black",
              height: 2,
              width: "20%",
              maxWidth: "500px",
              mt: 3,
              marginBottom: 2,
            }}
          />
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: { slidesPerView: 1 }, // Mobile
              768: { slidesPerView: 4 }, // Tablet
              1024: { slidesPerView: 5 }, // Large screens
            }}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {clients.map((client, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 2,
                    boxShadow: "none",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: 250,
                      height: 250,
                      borderRadius: "20%",
                      marginTop: 5,
                      marginBottom: 4,
                    }}
                    image={client.image}
                    alt={`Client ${index + 1}`}
                  />
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      </div>
    </>
  );
}
