import React from "react";
import { WhatsApp } from "@mui/icons-material";
import {
  Stack,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
} from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import { Phone, LocationOn } from "@mui/icons-material";
import MAAWhite from "../assets/MAA_White.png";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const Footer = () => {
  const services = [
    "Content Creation ",
    "Social Media Management",
    "Brand Activations",
    "Web Design",
    "Brand Identity",
  ];
  const [scrollDirection, setScrollDirection] = useState("down");
  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);
  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
    },
  });

  return (
    <Box
      sx={{
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: -1, // Ensure it's behind the content
        backgroundColor: "black",
        color: "white",
        padding: "20px 20px",
        fontFamily: "Century Gothic, sans-serif",
        marginRight: { sm: 0 },

        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Grid
        container
        spacing={1}
        
        justifyContent={{ xs: "center",sm:"space-between"}}
        alignItems="center"
        textAlign={{ xs: "center",  }}
        
        
      >
        {/* Logo Section */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={getAnimation(0)}
          viewport={{ amount: 0.2 }}
        >
          <Grid item xs={12} sm={3} textAlign="center" 
          
      alignItems={{ xs: "center",  }}
        >
            <Box>
              <Box
                component="img"
                src={MAAWhite}
                alt="Logo"
                sx={{
                  alignItems: { xs: "center"},
                  marginLeft: { xs:0,sm: 6 },
                  marginTop: -5,
                  //height: "300px",
                  height: { xs: "250px", sm: "300px" },
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
        </motion.div>

        {/* Contact Info Section */}

        <Grid
          item
          xs={12}
          sm={6}
          
          textAlign={{ xs: "center", sm: "left" }}
          sx={{
            //marginLeft: { sm: 2 },
            paddingLeft: { sm: 4 },
            //alignItems: { xs: "center", sm: "center" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: { sm: 10 },
              //marginLeft: { sm: 5 },
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            {/* Title */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={getAnimation(0)}
              viewport={{ amount: 0.2 }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 400,
                  marginBottom: 2,
                  textAlign: { xs: "center", sm: "left" },
                  marginTop: { xs: -4, sm: 3 },
                  fontFamily: "Century Gothic",
                }}
              >
                Contact Us
              </Typography>
            </motion.div>

            {/* Contact Details */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={getAnimation(1)}
                  viewport={{ amount: 0.2 }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Phone />
                    <Typography variant="body2">
                      <a
                        href="tel:+271000015089"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        +27 10 001 5089
                      </a>
                    </Typography>
                  </Stack>
                </motion.div>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={getAnimation(2)}
                  viewport={{ amount: 0.2 }}
                >

                  <Stack direction="row" spacing={1} alignItems="center">
                    <Phone />
                    <Typography variant="body2">
                      <a
                        href="tel:+271000015089"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        +27 10 001 5089
                      </a>
                    </Typography>
                  </Stack>
                </motion.div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  width: { xs: "100%", sm: "60%" },
                  justifyContent: { xs: "center", sm: "flex-start" },
                }}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={getAnimation(3)}
                  viewport={{ amount: 0.2 }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                  <LocationOn sx={{ padding: 0, margin: 0 }} />
                  <Typography
                    sx={{
                      fontFamily: "Century Gothic",
                      fontSize: "16px",
                      letterSpacing: 1,
                      color: "white",
                      textAlign: { xs: "center", sm: "left" },
                      fontWeight: 300,
                      width: "100%",
                    }}
                  >
                    Unit 27, Pomona Junction Corner Bon Cretion, Constantia St,
                    Pomona, Kempton Park, 161
                  </Typography>
                  </Stack>
                </motion.div>
              </Box>
            </Box>

            {/* Social Media Links */}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" },
                gap: 2,
                marginTop: 3,
              }}
            >
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(0)}
                viewport={{ amount: 0.2 }}
              >
                <IconButton
                  sx={{
                    transition: "transform 0.2s ease, color 0.2s ease",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#D8D8D8",
                      transform: "scale(1.0)",
                    },
                  }}
                  color="inherit"
                  component="a"
                  href="https://www.facebook.com/share/16C3pyTMYm/?mibextid=wwXIfr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </IconButton>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(0)}
                viewport={{ amount: 0.2 }}
              >
                <IconButton
                  sx={{
                    transition: "transform 0.2s ease, color 0.2s ease",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#D8D8D8",
                      transform: "scale(1.0)",
                    },
                  }}
                  color="inherit"
                  component="a"
                  href="https://www.instagram.com/megaleioadvertising?igsh=NmRxcm84dnNwbDNn&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </IconButton>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(0)}
                viewport={{ amount: 0.2 }}
              >
                <IconButton
                  sx={{
                    transition: "transform 0.2s ease, color 0.2s ease",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#D8D8D8",
                      transform: "scale(1.0)",
                    },
                  }}
                  color="inherit"
                  component="a"
                  href="https://www.instagram.com/megaleioadvertising?igsh=NmRxcm84dnNwbDNn&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </IconButton>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={getAnimation(0)}
                viewport={{ amount: 0.2 }}
              >
                <IconButton
                  sx={{
                    transition: "transform 0.2s ease, color 0.2s ease",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#25D366",
                      transform: "scale(1.0)",
                    },
                  }}
                  color="inherit"
                  component="a"
                  href="https://wa.me/+27817411823"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsApp />
                </IconButton>
              </motion.div>
            </Box>
          </Box>
        </Grid>

        {/* Services Section */}
        <Grid
          item
          xs={12}
          sm={3}
          textAlign={{ xs: "center", sm: "left" }}
          marginTop={{ xs: 10, sm: 0, lg: 0 }}
        >
          <Box>
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={getAnimation(0)}
              viewport={{ amount: 0.2 }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 400,
                  marginBottom: 2,
                  textAlign: { xs: "center", sm: "left" },

                  fontFamily: "Century Gothic",
                }}
              >
                Services
              </Typography>
            </motion.div>
            <List sx={{ textAlign: { xs: "center", sm: "left" } }}>
              {services.map((service, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  variants={getAnimation(index + 4)}
                  viewport={{ amount: 0.2 }}
                >
                  <ListItem key={index} sx={{ padding: "2px 0" }}>
                    <ListItemText
                      primary={service}
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: "Century Gothic",
                          fontSize: "16px",
                          letterSpacing: 1,
                          color: "white",
                          textAlign: { xs: "center", sm: "left" },
                          fontWeight: 300,
                        },
                      }}
                    />
                  </ListItem>
                </motion.div>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          textAlign: "center",
          marginTop: 2,
          borderTop: "1px solid white",
          paddingBottom: 2,
          fontFamily: "Century Gothic",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginTop: 2,
          }}
        >
          &copy; Copyright © 2025 Megaleio
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
