import React, { useState } from "react";
import { WhatsApp } from "@mui/icons-material";

import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Grid,
  IconButton,
} from "@mui/material";

import axios from 'axios'; 

import { motion } from "framer-motion";
  import { useEffect } from "react";
  
  


const ContactForm = () => {

  
    const [scrollDirection, setScrollDirection] = useState("down");
      
        // Detect scroll direction
        useEffect(() => {
          let lastScrollY = window.scrollY;
      
          const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            setScrollDirection(scrollY > lastScrollY ? "down" : "up");
            lastScrollY = scrollY;
          };
      
          window.addEventListener("scroll", updateScrollDirection);
          return () => window.removeEventListener("scroll", updateScrollDirection);
        }, []);
      
        // Animation variants based on scroll direction
        const getAnimation = (index) => ({
          hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
          visible: {
            opacity: 1,
            y: 0,
            transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
          },
        });

        //
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const re = /^[^@]+@[^@]+\.[^@]+$/; // Corrected email regex
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/; // Corrected phone regex for 10 digits
    return re.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, surname, email, phone, message } = formData;

    if (
      !name ||
      !surname ||
      !validateEmail(email) ||
      !validatePhone(phone) ||
      !message
    ) {
      setError("Please fill all fields correctly.");
      return;
    }

    setError("");
    setSuccess(false); // Reset success state before sending request

    try {
      // Make the POST request to the backend
      //const response = await axios.post('http://localhost:5000/send-email', formData);
      const response = await axios.post('https://megaleio.co.za/send-email', formData);

      if (response.data.success) {
        setSuccess(true); // Show success message
      } else {
        setError(response.data.message); // Show error message
      }
    } catch (error) {
      setError("An error occurred while submitting the form.");
    }

    // Reset form after submission
    setFormData({
      name: "",
      surname: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  



  return (
    <div
  id="trigger-section"
  style={{
    // Full content height and a neutral background for visualization
    minHeight: "100vh",
    //backgroundColor: "lightgray",
  }}
>
    <Box
      sx={{
       
      }}
    >
      <Box
        sx={{
          width: "100vw",
          minHeight: { xs: "115vh", sm: "130vh", md: "130vh", lg: "130vh" },
          //height: { xs: "100vh", sm: "100vh", md: "100vh", lg: "100vh" },
          overflowX: "hidden",
          overflowY: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(51, 51, 51, 0.2)",
          backgroundImage: "url(assets/servicebg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          animation: "moveBackground 30s linear infinite",
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
                       
            <Typography
              variant="h6"
              sx={{
                letterSpacing: 2,
                textTransform: "uppercase",
                fontSize: { xs: "1rem", sm: "1.3rem" },
              }}
            >
              Megaleio Advertising Agency
            </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
                       
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, fontSize: { xs: "3rem", sm: "3.8rem" } }}
            >
              CONTACT US
            </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          marginBottom: 0,

          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            marginBottom: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
              minHeight: { xs: "150vh", sm: "110vh", md: "110vh", lg: "110vh" },
              backgroundImage: "url('/assets/call.png')",
              backgroundSize: "contain",
              backgroundPosition: "left center",
              backgroundRepeat: "no-repeat",
              boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              marginTop: { xs: 0, md: 0, lg: 0 },
              
             
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                maxWidth: 1200,

                marginLeft: { xs: 0, sm: 0, md: 42, lg: 42 },
                marginTop: { xs: -25, sm: 0 },
                "@media (max-width: 375px)": {
                  marginTop: { xs: -8,}
                },
                padding: 3,
                backgroundColor: "rgba(255, 255, 255, 0.1)",

                borderRadius: 2,
                
                boxShadow: "none",
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <motion.div initial="hidden" whileInView="visible" variants={getAnimation(0)} viewport={{ amount: 0.2 }}>
                           
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    marginTop: { xs:0, sm: 0, md: 1, lg: 1 },
                    marginBottom: 3,

                    paddingTop: "40px",
                    textAlign: "center",
                    position: "relative",

                    "@media (max-width: 480px)": {
                      fontSize: "1.5rem",
                    },

                    "&:before": {
                      content: '""',
                      display: "block",
                      width: "370px",
                      height: "1px",
                      background: "#000",
                      position: "absolute",
                      left: 0,
                      top: "75%",
                    },

                    "&:after": {
                      content: '""',
                      display: "block",
                      width: "370px",
                      height: "1px",
                      background: "#000",
                      position: "absolute",
                      right: 0,
                      top: "75%",
                    },

                    "@media (max-width: 1024px)": {
                      "&:before, &:after": {
                        width: "30%",
                        top: "75%",
                      },
                    },

                    "@media (max-width: 1280px)": {
                      "&:before, &:after": {
                        width: "30%",
                        top: "75%",
                      },
                    },
                    "@media (max-width: 412px)": {
                      marginTop:-15,
                    },
                  
                    "@media (max-width: 912px)": {
                      "&:before, &:after": {
                        width: "23%",
                        top: "75%",
                      },
                    },

                    "@media (max-width: 600px)": {
                      fontSize: "2rem",
                      "&:before, &:after": {
                        width: "20%",
                        top: "75%",
                      },
                    },
                    fontFamily: "Century Gothic",

                    letterSpacing: 1,
                  }}
                >
                  Let’s Talk
                </Typography>
                </motion.div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  marginBottom: 2,
                }}
              ><motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
                         
                <form onSubmit={handleSubmit} style={{}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: { xs: "black", sm: "gray" },
                            fontFamily: "Century Gothic",

                            letterSpacing: 1,
                            "&.Mui-focused": {
                              color: "black",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Surname"
                        name="surname"
                        value={formData.surname}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: { xs: "black", sm: "gray" },
                            fontFamily: "Century Gothic",

                            letterSpacing: 1,
                            "&.Mui-focused": {
                              color: "black",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        type="email"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: { xs: "black", sm: "gray" },
                            fontFamily: "Century Gothic",

                            letterSpacing: 1,
                            "&.Mui-focused": {
                              color: "black",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        type="tel"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: { xs: "black", sm: "gray" },
                            fontFamily: "Century Gothic",

                            letterSpacing: 1,

                            "&.Mui-focused": {
                              color: "black",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        multiline
                        rows={4}
                        sx={{
                          fontFamily: "Century Gothic",

                          letterSpacing: 1,

                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            // Set the default label color
                            color: { xs: "black", sm: "gray" },
                            fontFamily: "Century Gothic",

                            letterSpacing: 1,

                            "&.Mui-focused": {
                              color: "black",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      width: "100%",
                      fontFamily: "Century Gothic",

                      letterSpacing: 1,
                      backgroundColor: "black",

                      "&:hover": {
                        backgroundColor: "grey",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
                </motion.div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: { xs: 0, sm: 0, md: 2, lg: 2 },
                  gap: { xs: 0, sm: 0, md: 2, lg: 2 },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    marginLeft: { xs: 0, md: 2 },
                    marginTop: { xs: 0, md: 4 },
                    gap: { xs: 0, md: 2 },
                  }}
                >
                  <Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        marginTop: { xs: 0, md: 4 },
                        gap: { xs: 0, md: 2 },
                      }}
                    >
                      <motion.div initial="hidden" whileInView="visible" variants={getAnimation(1)} viewport={{ amount: 0.2 }}>
                                 
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: "Century Gothic",

                          letterSpacing: 1,
                          fontSize: { xs: "1.1rem", sm: "1.25rem" },
                          color: { xs: "white", sm: "inherit" },
                        }}
                      >
                        Phone Number
                      </Typography>
                      </motion.div>
                      <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
                                 
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          color: {
                            xs: "white",
                            sm: "inherit",
                            fontFamily: "Century Gothic",
                            fontSize: { xs: "0.77rem", sm: "1rem" },
                            letterSpacing: 1,
                          },
                          marginBottom: 2,
                        }}
                      >
                        <a
                          href="tel:+271000015089"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          +27 10 001 5089
                        </a>
                      </Typography>
                      </motion.div>
                      <motion.div initial="hidden" whileInView="visible" variants={getAnimation(2)} viewport={{ amount: 0.2 }}>
                                 
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: {
                            xs: "white",
                            sm: "inherit",
                            fontFamily: "Century Gothic",
                            fontSize: { xs: "1.1rem", sm: "1.25rem" },
                            letterSpacing: 1,
                          },
                        }}
                      >
                        Address
                      </Typography>
                      </motion.div>
                      <motion.div initial="hidden" whileInView="visible" variants={getAnimation(3)} viewport={{ amount: 0.2 }}>
                                 
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          color: { xs: "white", sm: "inherit" },
                          marginBottom: 2,

                          fontFamily: "Century Gothic",
                          fontSize: { xs: "0.8rem", sm: "1rem" },
                          letterSpacing: 1,
                        }}
                      >
                        Unit 27 Pomona Junction, Corner Bon Cretion and
                        Costantia St, Pomona Kempton Park, 1619
                      </Typography>
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",

                    marginTop: { xs: 5, md: 3 },
                   
                    gap: 2,
                  }}
                >
                  <motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
                             
                  <IconButton
                    sx={{
                      transition: "transform 0.2s ease, color 0.2s ease",
                      color: { xs: "gray", sm: "inherit" },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#25D366",
                        transform: "scale(1.0)",
                      },
                    }}
                    color="inherit"
                    component="a"
                    href="https://wa.me/+27817411823"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WhatsApp />
                  </IconButton>
                  </motion.div>
                  <motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
                             
                  <IconButton
                    sx={{
                      transition: "transform 0.2s ease, color 0.2s ease",
                      color: { xs: "gray", sm: "inherit" },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#A0A0A0",

                        transform: "scale(1.0)",
                      },
                    }}
                    color="inherit"
                    component="a"
                    href="https://www.facebook.com/share/16C3pyTMYm/?mibextid=wwXIfr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </IconButton>
                  </motion.div>
                  <motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
                             
                  <IconButton
                    sx={{
                      transition: "transform 0.2s ease, color 0.2s ease",
                      color: { xs: "gray", sm: "inherit" },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#D8D8D8",
                        transform: "scale(1.0)",
                      },
                    }}
                    color="inherit"
                    component="a"
                    href="https://www.instagram.com/megaleioadvertising?igsh=NmRxcm84dnNwbDNn&utm_source=qr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn />
                  </IconButton>
                  </motion.div>
                  <motion.div initial="hidden" whileInView="visible" variants={getAnimation(4)} viewport={{ amount: 0.2 }}>
                             
                  <IconButton
                    sx={{
                      transition: "transform 0.2s ease, color 0.2s ease",
                      color: { xs: "gray", sm: "inherit" },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#D8D8D8",
                        transform: "scale(1.0)",
                      },
                    }}
                    color="inherit"
                    component="a"
                    href="https://www.instagram.com/megaleioadvertising?igsh=NmRxcm84dnNwbDNn&utm_source=qr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </IconButton>
                  </motion.div>
                </Box>
              </Grid>

              {error && (
                <Snackbar
                  open={Boolean(error)}
                  autoHideDuration={6000}
                  onClose={() => setError("")}
                >
                  <Alert onClose={() => setError("")} severity="error">
                    {error}
                  </Alert>
                </Snackbar>
              )}
              <Snackbar
                open={success}
                autoHideDuration={6000}
                onClose={() => setSuccess(false)}
              >
                <Alert onClose={() => setSuccess(false)} severity="success">
                  Feedback submitted successfully!
                </Alert>
              </Snackbar>
            </Grid>
          </Box>
          {/* Map Section */}
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "400px",
                borderRadius: 2,
                overflow: "hidden",
                "@media (max-width: 412px)": {
                  marginTop:-24,
                },

                "@media (max-width: 375px)": {
                  marginTop:-10,
                },
                
              }}
            >
              {/* Replace with your actual map embed */}
              <iframe
                title="Google Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.565708286578!2d28.273320199999993!3d-26.1130918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4e5dd9d933dbd6ff%3A0xb1512cde72b55c9!2sMegaleio%20Advertising%20Agency!5e0!3m2!1sen!2sza!4v1739194541874!5m2!1sen!2sza"
                width="100%"
                height="100%"
               
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Box>
          </Grid>
        </Box>
      </Box>
      
    </Box>
    </div>
  );
};

export default ContactForm;
