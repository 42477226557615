
import { motion } from "framer-motion";
import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";  

export default function Ourservices() {
  const boxes = useMemo(
    () => [
      {
        id: 1,
        title: "(NLP) Services",
        desc: "Leverages AI to understand, analyze, and generate human language.",
        bg: "/assets/api.png",
      },
      {
        id: 2,
        title: "Social Media Management",
        desc: "Planning and automating posts to go live at optimal times.",
        bg: "/assets/Marketingservices.png",
      },
      {
        id: 3,
        title: "Web Development",
        desc: "Building responsive and fast websites.",
        bg: "/assets/web.jpeg",
      },
    ],
    []
  );
  
  const containerRef = useRef(null);
  const navigate = useNavigate();
  
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px) and (min-width:601px)");
  const isDesktop = useMediaQuery("(min-width:961px)");
  const [randomBox, setRandomBox] = useState(boxes[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [scrollDirection, setScrollDirection] = useState("down");

  const autoSlideRef = useRef(null);

const swipeHandlers = useSwipeable({
  onSwipedLeft: () => {
    clearInterval(autoSlideRef.current);
    setActiveIndex((prevIndex) => (prevIndex + 1) % boxes.length); // Move to next box
  },
  onSwipedRight: () => {
    clearInterval(autoSlideRef.current);
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + boxes.length) % boxes.length // Move to previous box
    );
  },
});

//


//


  // ✅ Sync `randomBox` with `activeIndex`
useEffect(() => {
  setRandomBox(boxes[activeIndex]);
  setAnimationKey((prevKey) => prevKey + 1); // ✅ Force re-animation
},[activeIndex, boxes]);
  
  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);

  // Animation variants based on scroll direction

  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.5, duration: 0.8, ease: "easeOut" }, // ✅ Added more delay
    },
  });

  const [animationKey, setAnimationKey] = useState(0); 

  useEffect(() => {
    if (isMobile) {
      let index = 0;
      autoSlideRef.current = setInterval(() => {
      //const interval = setInterval(() => {
        setActiveIndex(index);
        setRandomBox(boxes[index]);  
        setAnimationKey((prevKey) => prevKey + 1); 
        index = (index + 1) % boxes.length; 
      },8000); 
          
    return () => clearInterval(autoSlideRef.current);
      //return () => clearInterval(interval); 
    }
  }, [isMobile, boxes]);
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "auto",
        maxHeight: "825px",
        backgroundImage: "url('/assets/home.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      


 

{isMobile && randomBox && (
  <Box
    {...swipeHandlers}
    ref={containerRef}
    sx={{
      display: "flex",
      overflowX: "auto",
      overflowY: "auto",
      paddingBottom: 1,
      justifyContent: "center",
      width: "100%",
      position: "relative",
      marginTop: 10,
      marginBottom: 4,
      touchAction: "pan-y", 
    }}
  >
    <Grid
      item
      key={boxes[activeIndex].id}
      sx={{
        margin: "0 auto",
        maxWidth: "340px",
        width: "100%",
        height: "500px",
        backgroundImage: `url(${boxes[activeIndex].bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        padding: 2,
        position: "relative",
        transition: "transform 0.3s ease", 
        "&:hover": {
          transform: "scale(1.05)", 
        },
      }}
      onClick={() => navigate("/services")}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: 2,
          borderRadius: "8px",
          marginBottom: 2,
          position: "absolute",
          bottom: 0,
          maxWidth: "300px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <motion.div
          key={animationKey} 
          initial="hidden"
          whileInView="visible"
          variants={getAnimation(1)}
          viewport={{ amount: 0.2 }}
        >
          <Typography variant="h5" sx={{ fontWeight: 400 }}>
            {boxes[activeIndex].title}
          </Typography>
        </motion.div>
        <motion.div
          key={animationKey + 1}
          initial="hidden"
          whileInView="visible"
          variants={getAnimation(2)}
          viewport={{ amount: 0.2 }}
        >
          <Typography variant="body1">
            {boxes[activeIndex].desc}
          </Typography>
        </motion.div>
      </Box>
    </Grid>
  </Box>
)}

{/* Dots should also be conditionally rendered only on mobile */}
{isMobile && (
  <Box sx={{ display: "flex", justifyContent: "center", mt: 1, marginTop: 3, marginBottom: 8 }}>
    {boxes.map((_, index) => (
      <Box
        key={index}
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: index === activeIndex ? "white" : "gray",
          margin: "0 6px", 
          transition: "background-color 0.3s ease",
        }}
        onClick={() => setActiveIndex(index)} 
      />
    ))}
  </Box>
)}



      {isTablet && (
        <Box
          sx={{
            
            marginTop: 2,
            marginLeft: 5,
            marginBottom: 10,
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowX: "auto",
            overflowY: "scroll", // Ensure vertical scroll is hidden
            height: "800px",
            scrollBehavior: "smooth",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              scrollBehavior: "smooth",
              width: "100%",
              paddingBottom: 1,
            }}
          >
            <Grid container spacing={3} justifyContent="center">
              {boxes.map((box) => (
                <Grid item key={box.id} xs={6}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={getAnimation(0)}
                    viewport={{ amount: 0.2 }}
                  >
                    <Box
                      sx={{
                        height: "480px",
                        backgroundImage: `url(${box.bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        color: "white",
                        textAlign: "center",
                        padding: 2,
                        position: "relative",
                        transition: "transform 0.3s ease", // Zoom out effect
                        "&:hover": {
                          transform: "scale(1.05)", // Zoom out effect on hover
                        },
                      }}
                      onClick={() => navigate("/services")}
                    >
                      <Box
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: 2,
                          borderRadius: "8px",
                          marginBottom: 2,
                          position: "absolute",
                          bottom: 0,
                          maxWidth: "360px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={getAnimation(1)}
                          viewport={{ amount: 0.2 }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{ letterSpacing: 4 }}
                          >
                            {box.title}
                          </Typography>
                        </motion.div>
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={getAnimation(2)}
                          viewport={{ amount: 0.2 }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              letterSpacing: 4,
                            }}
                          >
                            {box.desc}
                          </Typography>
                        </motion.div>
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

        </Box>
      )}

      {isDesktop && (
        <Box
          sx={{
            marginTop: 20,
            marginLeft: 3,
            marginBottom: 10,
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            display: "flex",
            overflowX: "hidden",
            overflowY: "hidden",
            height: "900px",
            scrollBehavior: "smooth",
            maxWidth: "1230px",
            width: "100%",
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            {boxes.map((box) => (
              <Grid item key={box.id} xs={4}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={getAnimation(0)}
                  viewport={{ amount: 0.2 }}
                >
                  <Box
                    sx={{
                      height: "490px",
                      backgroundImage: `url(${box.bg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      color: "white",
                      textAlign: "center",
                      padding: 2,
                      position: "relative",
                      cursor: "pointer",
                      transition: "transform 0.3s ease", // Smooth transition for scaling
                      "&:hover": {
                        transform: "scale(1.05)", // Zoom out effect on hover
                      },
                    }}
                    onClick={() => navigate("/services")}
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        padding: 2,
                        borderRadius: "8px",
                        marginBottom: 2,
                        position: "absolute",
                        bottom: 0,
                        maxWidth: "360px",
                        width: "100%",
                        textAlign: "center",
                        marginRight: 2,
                      }}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        variants={getAnimation(1)}
                        viewport={{ amount: 0.2 }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          sx={{
                            fontWeight: 400,
                            letterSpacing: 1,
                            textAlign: "center",
                          }}
                        >
                          {box.title}
                        </Typography>
                      </motion.div>
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        variants={getAnimation(2)}
                        viewport={{ amount: 0.2 }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            letterSpacing: 1,
                            textAlign: "center",
                            marginTop: 2,
                          }}
                        >
                          {box.desc}
                        </Typography>
                      </motion.div>
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
