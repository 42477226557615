

import React, { useState } from "react";
import { IconButton, Typography,  Stack, } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MenuOptionList from "../pages/MenuList.jsx";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { useEffect } from "react";




//
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  z-index: 1100;
`;


const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.5);
  width: 450px;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  z-index: 1000;

   /* Adjust width for mobile */
  @media (max-width: 600px) {
    width: 250px; /* Or any width you prefer for mobile */
  }

  background-color: ${({ open }) =>
    open ? "rgba(255, 255, 255, 0.4)" : "transparent"};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;

  /* Avoid passing non-DOM attributes */
  ${(props) => props.open && `visibility: visible;`}
`;



const AnimatedMenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const AnimatedMenuItem = styled.div.attrs(({ $index }) => ({
  style: { animationDelay: `${$index * 0.2}s` }
}))`
  animation: ${slideIn} 0.5s ease-out;
  animation-fill-mode: both;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    display: none;
  }
`;
export default function MenuBar() {


  const [scrollDirection, setScrollDirection] = useState("down");
  // Detect scroll direction
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);
  const getAnimation = (index) => ({
    hidden: { opacity: 0, y: scrollDirection === "down" ? 50 : -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.8, ease: "easeOut" },
    },
  });
  //
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <> <motion.div
    initial="hidden"
    whileInView="visible"
    variants={getAnimation(1)}
    viewport={{ amount: 0.2 }}
  ><Stack direction="row" spacing={1} alignItems="center">
      <IconButton onClick={handleToggle} color="black">
        <MenuIcon />
      </IconButton>
      <MenuContainer>

        <Typography variant="h6" color="black">
          MENU
        </Typography>
      </MenuContainer>
      </Stack>
      </motion.div>

      <StyledMenu open={open}>
        {open && (
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        )}
        {open && (
          <AnimatedMenuList>
            {MenuOptionList({ handleClose }).props.children.map((child, index) => (
              <AnimatedMenuItem key={index} $index={index}>
                {child}
              </AnimatedMenuItem>
            ))}
          </AnimatedMenuList>
        )}
      </StyledMenu>

      {open && (
        <div
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9,
            color:"black",
            backgroundColor: open ? "rgba(0, 0, 0, 0.5)" : "none",
          }}
        />
      )}
    </>
  );
}
